import { textRecipe } from './text.css';

import * as React from 'react';

import Box, { type BoxProps } from '../layout/Box';
import type { TextVariants } from './text.css';

export type TextProps = {
  children: React.ReactNode;
  color?: BoxProps['color'];
  as?: BoxProps['as'];
} & TextVariants &
  Omit<BoxProps, 'textAlign'> & {
    clampedLines?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10; // ... add whatever number you need, we just want to exclude 0 and floats here, and there is no "proper" way to do it in TS
  };

/**
 * Text component used for titles, body copy, and text labels.
 *
 * `<a>` tags inside this component will be underlined by default.
 *
 * This should be used with 2024 variants.
 * It also includes older variants (considered "legacy").
 * */
export const Text: React.FC<TextProps> = ({
  children,
  variant,
  color = 'primary.400',
  as = 'p',
  fontWeight,
  textAlign,
  textDecorationLine,
  fontStyle,
  ellipsis,
  clampedLines,
  ...props
}: TextProps) => {
  const hasEllipsis = Boolean(ellipsis || clampedLines);

  return (
    <Box
      as={as}
      className={textRecipe({
        variant,
        fontWeight,
        textAlign,
        textDecorationLine,
        fontStyle,
        ellipsis: hasEllipsis,
      })}
      {...(hasEllipsis
        ? {
            style: {
              '--clamped-lines': clampedLines || 1,
            },
          }
        : {})}
      color={color}
      margin="none"
      {...props}
    >
      {children}
    </Box>
  );
};

export default Text;
