import { sprinkles } from '../sprinkles.css';

import { forwardRef } from 'react';

import { createBox } from '@dessert-box/react';
import { Slot } from '@radix-ui/react-slot';

const BoxOg = createBox({ atoms: sprinkles });

export type BoxProps = Parameters<typeof BoxOg>[0] & { asChild?: boolean };

const Box = forwardRef<HTMLElement, BoxProps>(({ asChild = false, as = 'div', ...props }, ref) => {
  if (asChild) {
    return <BoxOg ref={ref} {...props} as={Slot} />;
  }
  return <BoxOg ref={ref} {...props} as={as} />;
});

Box.displayName = 'Box';

export default Box;
